.home{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .top{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 150px 0;
  }
  .top-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 20px;
  
  }
  .top-left-header{
    font-size: 48px;
    font-weight: 300;
    line-height: 66px;
    margin-bottom: 20px;
    color:#091133;
  }
  .top-left-subtitle{
    font-size: 18px;
    max-width: 550px;
    margin-bottom: 30px;
    line-height: 30px;
    color:#505F98;
  }
  .top-left-button{
    font-size: 16px;
    font-weight: 700;
    padding:10px 40px;
    background: none;
    color:#091133;
    border: 2px solid #091133;
  }
  .top-left-button:hover{
    transform: translate(0, -2px);
    }
  .top-right{
    margin-left: 20px;
  }
  .top-right-image{
   width:482px;
   
  }
  .bottom{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 60px 0;
  }
  .bottom-header{
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .bottom-social{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  
  }
  .bottom-left{
    overflow-y: scroll;
    width:35vw;
    max-height:  428px;
  }
  .bottom-right{
    overflow-y: scroll;
    width:35vw;
    max-height:  428px;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .social::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .social {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  iframe{
    height:850px;

  }
  @media only screen and (max-width: 1300px) {
    .top-left-header{
      font-size: 40px;
      font-weight: 300;
      line-height: 66px;
      margin-bottom: 20px;
      color:#091133;
    }
    .top-left-subtitle{
      font-size: 16px;
      max-width: 450px;
      margin-bottom: 30px;
      line-height: 20px;
      color:#505F98;
    }
    .top-right-image{
      width: 300px;
    }
  }
  @media only screen and (max-width: 900px) {
    .top{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 150px 0;
      text-align: center;
    }
    .top-left{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0;
    }
    .top-right-image{
     display: none;
     }
     .top-left-header{
      font-size: 38px;
      font-weight: 300;
      line-height: 66px;
      margin-bottom: 20px;
      color:#091133;
    }
    .top-left-subtitle{
      font-size: 16px;
      max-width: 550px;
      margin-bottom: 30px;
      line-height: 30px;
      color:#505F98;
    }
    .bottom{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 60px 0;
    }
    .bottom-left{
      overflow-y: scroll;
      width:350px;
      max-height:  350px;
      margin-bottom: 150px;
    }
    .bottom-right{
      overflow-y: scroll;
      width:350px;
      max-height:  350px;
    }
    iframe{
      height:650px;
    }
  }