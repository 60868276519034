.footer{
    width:100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}
.footer-top{
    color:grey;
    font-size: 15px;
}
.footer-hr{
    width:100%;
    margin:20px 0;
}
.footer-bottom{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
}
.footer-bottom-links{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 300px;
    align-items: center;
}
.footer-bottom-links>*{
    font-size: 15px;
    text-decoration: none;
    color:#929ECC;
}
.footer-bottom-social{
    width:50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
img{
    cursor: pointer;
}
