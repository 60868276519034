.header{
    position: fixed;
    width:100%;
    height:80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    z-index: 2;
}
.header-logo{
    width: 200px;
}
.header-links{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.header-links>*{
    text-decoration: none;
    color:#929ECC;
}
img{
    cursor: pointer;
}
@media only screen and (max-width: 900px) {
    .header-logo{
        width: 150px;
    }
}