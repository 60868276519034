*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  min-height: 100vh;
  width: 100%;
  height: 100%;
}

body {
  position: relative;
  font-family: 'Roboto', sans-serif;
  background-color: "white";
  width: 100%;
  height: 100%;
}
.header-wrapper {
  padding:20px 180px;
}
.footer-wrapper {
  padding:20px 180px;
}
.page-wrapper{
  padding:20px 180px;
}
@media only screen and (max-width:1300px) {
  .header-wrapper {
    padding:20px 80px;
  }
  .footer-wrapper {
    padding:20px 80px;
  }
  .page-wrapper{
    padding:20px 80px;
  }
}
@media only screen and (max-width: 900px) {
  .header-wrapper {
    padding:20px 20px;
  }
  .footer-wrapper {
    padding:20px 20px;
  }
  .page-wrapper{
    padding:20px 20px;
  }
}