.career{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    line-height: 66px;
}
.career-header{
    font-size: 24px;
    font-weight: 500;
    color:#091133;
}
a{
    font-weight: 300;
    font-size: 20px;
    text-decoration: none;
    color:#091133;
    cursor: pointer;
}
.img{
    width:100%;
    min-height: 200px;
}
@media only screen and (max-width: 900px) {
    .career{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        line-height: 46px;
    }
    .career>*{
        font-weight: 200;
        font-size: 16px;
        text-decoration: none;
        color:#091133;
    }
    .img{
        width:100%;
        min-height: 150px;
    }
}